.hero {
    height : calc(100vh - 70px) !important;
}
@media (max-width: 628px) {
    .hero {
        height: 75vh !important;
    }
}

.popular-cars-swiper {
    height : 350px !important;
    /* padding-left: 2rem !important;
    padding-right: 2rem !important; */

}
.popular-cars-swiper .swiper-pagination-bullet {
    width: 13px !important;
    height: 13px !important;
}
.popular-cars-swiper .swiper-button-next , .popular-cars-swiper .swiper-button-prev {
    background-color: rgba(0, 0, 0, 0.534) !important;
    width: 40px !important;
    height: 40px !important;
    border-radius: 50%;
    top : 45% !important;
}
.popular-cars-swiper .swiper-button-next:after {
    margin-left: 3px ;
}
.popular-cars-swiper .swiper-button-prev:after {
    margin-right: 3px ;
}

.testimonials-swiper {
    width: 50%;
    margin : 0 auto;
    overflow: hidden;
    /* padding : 0 10px; */
}

.testimonials-swiper .swiper-slide {
    width: 100% !important;
    margin : 0 auto !important;
    min-height : 350px !important;
    height: 100% !important;
    /* background: rgb(238, 238, 238); */
    @apply bg-pure rounded-lg shadow-md border mx-auto;
}

.testimonials-swiper .swiper-button-next::after , .testimonials-swiper .swiper-button-prev::after {
    color : var(--secondary) !important;
    font-weight: bold;
    font-size: 20px !important;
}


@media (max-width: 1040px) {
    .hero {
        height: 80vh !important;
    }
}


@media (max-width: 768px) {
    .testimonials-swiper {
        width: 100% !important;
        padding : 0 3rem !important;
    }
    .hero {
        height: 60vh !important;
    }
    .popular-cars-swiper .swiper-button-next , .popular-cars-swiper .swiper-button-prev {
        width: 30px !important;
        height: 30px !important;
    }
    .popular-cars-swiper .swiper-button-next:after {
        margin-left: 3px ;
        font-size: 14px !important;
    }
    .popular-cars-swiper .swiper-button-prev:after {
        margin-right: 3px ;
        font-size: 14px !important;
    }
}




