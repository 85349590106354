.nav-list {
    @apply lg:flex hidden items-center gap-8 ;
}

.nav-list-item {
    @apply text-white hover:text-opacity-80 text-[15px] relative;
}

.nav-list-item.active::after {
    @apply content-[''] absolute top-[105%] left-1/2 -translate-x-1/2 w-1/2 h-1 bg-green-500 
}