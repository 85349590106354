@tailwind base;
@tailwind components;
@tailwind utilities;
 
html {
    font-family: 'Poppins', sans-serif;
    scroll-behavior: smooth;
}


:root {
    --primary : #1E88E5;
    --primaryHover : #0566bb;
    --primaryGradientHover : linear-gradient(90.85deg, #C9E51E -16.92%, #1E88E5 99.27%);
    --primaryGradient : linear-gradient(90.85deg, #1E88E5 -16.92%, #C9E51E 99.27%);
    --secondary : #C9E51E;
    --grayText : #1E1E1E80;
    --dark : #3a3a3a ;
    --pure : #fff;
}

.overlay-gradient{
    background-image: linear-gradient(180deg, #28b44683 0%, #08f1008e 100%);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}


#select-box-input{
    padding : 6px 0 !important;
    outline : none !important; 
}

.css-t3ipsp-control{
    border : none !important;
}

@media print {
    .report {
        padding : 2rem;
    }
    .report table {
        border : 1px solid var(--dark);
    }
    .report table td , .report table th {
        border : 1px solid gray;
    }
}







.dashboard-card {
    box-shadow: 0px 0px 20px #e6e6e6,
                inset 0px 0px 20px #ffffff;
    border-radius: 20px;
    padding: 22px;
    height: 200px;
    width: 100%;
}
  

@media (max-width: 768px){
    .responsiveTable tbody tr td.pivoted {
        text-align: right !important; /* Change text alignment to center */
    }
   .responsiveTable tbody tr {
        border : 1px solid rgb(190, 190, 190) !important;
        border-radius: 4px !important;
   }
}


/* React Confirm Alert */
.react-confirm-alert-overlay {
    background : #000000bd !important;
}
.react-confirm-alert-body {
    border-radius: 8px !important;
    background : var(--primary);
    color : var(--dark) !important;
    padding: 20px !important;
}
.react-confirm-alert-body h1 {
    @apply text-xl font-semibold mb-2 text-primary;
}
.react-confirm-alert-body button{
    @apply mt-4;
}
.react-confirm-alert-body button:first-child{
    @apply bg-primary hover:bg-primaryHover;
}